import React from 'react';
import styled from 'styled-components';

const FeaturedLinksWrapper = styled.section`
	&.hostjane-main-FeaturedLinks {
		background: #ffffff;
		margin-bottom: 60px;
		text-align: center;
		padding: 40px 0 40px;
		.hostjane-main-FeaturedLinks__community-courses {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			span {
				background-color: transparent !important;
				color: #5b6987;
				display: inline-block;
			}
			a {
				color: #031b4e;
				text-decoration: none;
				&:hover {
					color: #0069ff;
				}
			}
			span,
			a {
				font-size: 16px;
				display: block;
				padding: 0 7.5px;
				line-height: 1.4;
			}
		}
		.hostjane-main-FeaturedLinks__community-products {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			hr {
				background: 0 0;
				border: 0;
				display: block;
				height: 1px;
				margin-bottom: 1em;
				width: 100%;
			}
			span {
				background-color: transparent !important;
				color: #5b6987;
				display: inline-block;
			}
			a {
				color: #031b4e;
				text-decoration: none;
				display: block;
				padding: 0 7.5px;
				&:hover {
					color: #0069ff;
				}
			}
			span,
			a {
				font-size: 16px;
				line-height: 1.4;
			}
		}
	}
`;

const FeaturedLinks = ({ communityFeatured, productsFeatured }) => {
	return (
		<FeaturedLinksWrapper className="hostjane-main-FeaturedLinks">
			<div className="hostjane-main-FeaturedLinks__community-courses">
				<span> {communityFeatured.title}</span>
				{communityFeatured.links.map(({ link, href }) => {
					return <a href={href}>{link}</a>;
				})}
			</div>
			<div className="hostjane-main-FeaturedLinks__community-products">
				<hr />
				<span>{productsFeatured.title}</span>
				{productsFeatured.links.map(({ link, href }) => {
					return <a href={href}>{link}</a>;
				})}
			</div>
		</FeaturedLinksWrapper>
	);
};

export default FeaturedLinks;
