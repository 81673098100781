import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.section`
	&.hostjane-main-HeroCommunity {
		height: 610px;
		@media screen and (max-width: 1024px) {
			height: auto;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
		}
		box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
		.hostjane-main-HeroCommunity__wrapper {
			background: transparent;
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
			background-position: bottom center;
			overflow: hidden;
			text-align: center;
			.hostjane-main-HeroCommunity__wrapper__hero-image-mobile {
				background-image: url(${props => props.heroImageMobile});
				display: none;
				width: 100%;
				height: 300px;
				background-color: #e6e7e8;
				background-repeat: no-repeat;
				background-attachment: scroll;
				background-size: cover;
				background-position: center;
				@media screen and (max-width: 1024px) {
					display: block;
				}
			}
			.hostjane-main-HeroCommunity__wrapper__hero-image-desktop {
				background-image: url(${props => props.heroImageDesktop});
				display: block;
				background-position: right center;
				width: 68%;
				height: 100%;
				position: absolute;
				margin-left: 32%;
				background-color: #e6e7e8;
				background-repeat: no-repeat;
				background-attachment: scroll;
				background-size: cover;
				@media screen and (max-width: 1024px) {
					display: none;
				}
			}
			.hostjane-main-HeroCommunity__wrapper__sub-overlay {
				background-color: transparent;
				background-image: -webkit-linear-gradient(
					28deg,
					rgba(0, 52, 80, 0.4) 48%,
					rgba(0, 52, 80, 0) 48.1%
				);
				min-height: 610px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@media screen and (max-width: 1024px) {
					display: none;
				}
			}
			.hostjane-main-HeroCommunity__wrapper__overlay {
				background-color: transparent;
				background-image: -webkit-linear-gradient(
					25deg,
					#003450 45%,
					rgba(0, 52, 80, 0) 45.1%
				);
				min-height: 610px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@media screen and (max-width: 1024px) {
					display: none;
				}
			}
			.hostjane-main-HeroCommunity__wrapper__gradient {
				display: block;
				position: absolute;
				top: 0px;
				width: 100%;
				height: 162px;
				background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
				@media screen and (max-width: 1024px) {
					display: none;
				}
			}
			.hostjane-main-HeroCommunity__wrapper__content-wrapper {
				position: absolute;
				top: 130px;
				width: 40%;
				margin-top: 0px;
				height: 480px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				left: 35px;
				line-height: 24px;
				text-shadow: 0px 0px 50px #000000;
				font-size: 25px;
				@media screen and (max-width: 1024px) {
					position: static;
					width: 100%;
					height: auto;
				}
				.hostjane-main-HeroCommunity__wrapper__content-wrapper__content {
					width: 100%;
					max-width: 500px;
					padding: 30px;
					background: transparent;
					margin: 0;
					box-sizing: border-box;
					color: #fff;
					@media screen and (max-width: 1024px) {
						background: #003450;
						padding: 15px;
						max-width: 100%;
					}
					h2 {
						display: none;
						font-size: 36px;
						background: none;
						border-radius: 0;
						box-shadow: none;
						line-height: 36px;
						margin: 0;
						padding: 0;
						text-transform: unset;
						color: #fff;
						width: 100%;
						&.desktop {
							display: block;
							font-size: 42px;
							line-height: 42px;
							margin: 0;
							padding: 0;
							text-transform: unset;
							color: #fff;
							font-weight: 700;
						}
					}
					p {
						max-width: 340px;
						margin: 0 auto;
						font-size: 18px;
						padding: 5px 0px;
						a {
							font-size: 14px;
							font-weight: normal;
							line-height: 26px;
							text-align: left;
							background: #e63241;
							color: #ffffff;
							-webkit-font-smoothing: antialiased;
							text-transform: uppercase;
							letter-spacing: 0.1rem;
							border: 1px solid #e63241;
							margin: 0.5em 0.5em 0em 0;
							padding: 0.5em 1em;
							transition: background-color 0.3s ease-out;
							display: inline-block;
							border-radius: 3px;
							max-height: 180px;
							font-weight: bold;
							text-decoration: none;
							&:hover {
								background: transparent;
							}
						}
					}
				}
			}
		}
	}
`;

const Hero = ({
	title,
	subtitle,
	heroImageDesktop,
	heroImageMobile,
	btnText,
	btnLink,
}) => {
	return (
		<HeroWrapper
			className="hostjane-main-HeroCommunity"
			heroImageDesktop={heroImageDesktop}
			heroImageMobile={heroImageMobile}
		>
			<div className="hostjane-main-HeroCommunity__wrapper">
				<div className="hostjane-main-HeroCommunity__wrapper__hero-image-mobile" />
				<div className="hostjane-main-HeroCommunity__wrapper__hero-image-desktop" />
				<div className="hostjane-main-HeroCommunity__wrapper__sub-overlay" />
				<div className="hostjane-main-HeroCommunity__wrapper__overlay" />
				<div className="hostjane-main-HeroCommunity__wrapper__gradient" />
				<div className="hostjane-main-HeroCommunity__wrapper__content-wrapper">
					<div className="hostjane-main-HeroCommunity__wrapper__content-wrapper__content">
						<h2 className="desktop">{title}</h2>
						<h2>{title}</h2>
						<p>{subtitle}</p>
						<p>
							<a href={btnLink}>
								{btnText}
							</a>
						</p>
					</div>
				</div>
			</div>
		</HeroWrapper>
	);
};

export default Hero;
