const campaignsData = [
	{
		campaignThumbnail: require('./hostjane-telegram.jpg'),
		title: 'Telegram channel',
		btnText: 'News',
		btnLink: 'https://t.me/hostjanecom',
	},
	{
		campaignThumbnail: require('./hostjane-discord.jpg'),
		title: 'From our talent pool',
		btnText: 'Read',
		btnLink: 'https://medium.com/better-work',
	},
	{
		campaignThumbnail: require('./hostjane-subreddit.jpg'),
		title: '/r/HostJane',
		btnText: 'Vote',
		btnLink: 'https://www.reddit.com/r/HostJane/',
	},
];

export default campaignsData;
