const heroData = {
	title: 'Discord Server',
	subtitle:
		'Find talent, job postings and more with Jane Jobs, the official discord for HJ.',
	btnText: 'Join Server',
	heroImageDesktop: require('./desktop-hero.jpg'),
	heroImageMobile: require('./mobile-hero.jpg'),
	btnLink: 'https://discord.gg/5rS6Tvd',
};

export default heroData;
