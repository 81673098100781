import { graphql } from 'gatsby';
import React from 'react';
import aboutNavData from '../../../site-data/about-page/aboutNav';
import {
	Alert,
	backgroundColor,
	backgroundGradient,
	textColor,
} from '../../../site-data/home/alert';
import AboutNav from '../../components/AboutPage/AboutNav';
import DismissableBanner from '../../components/DismissableBanner';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import MainNavigation from '../../components/MainNavigation';
import data from '../../../form-data';
import Hero from '../../components/Community/Hero';
import Campaigns from '../../components/Community/Campaigns';
import Tutorials from '../../components/Community/Tutorials';
import FeaturedLinks from '../../components/Community/FeaturedLinks';
import MainFooter from '../../components/MainFooter';
import heroData from '../../../site-data/Community/hero';
import campaignsData from '../../../site-data/Community/campaigns';
import { ReactComponent as CommunityLogo } from '../../svgs/nav/community-logo.svg';
import tutorialsData from '../../../site-data/Community/tutorials';
import featuredLinksData from '../../../site-data/Community/featured-links';

const CommunityPage = props => {
	return (
		<Layout {...props} hasFooter={false} banner={data.banner} fullWidth>
			<SEO
				title="HostJane Community: Community Center"
				description="Join the conversation and connect with buyers and sellers who are creating a world where anyone can work remotely to generate sustainable income streams."
			/>
			<DismissableBanner
				backgroundColor={backgroundColor}
				textColor={textColor}
				backgroundGradient={backgroundGradient}
			>
				{Alert === null ? null : <Alert />}
			</DismissableBanner>
			<AboutNav
				fullWidth
				navTitle={<CommunityLogo />}
				navLinks={aboutNavData.navLinks}
				btnText={aboutNavData.btnText}
				btnLink={aboutNavData.btnLink}
			/>
			<Hero
				title={heroData.title}
				subtitle={heroData.subtitle}
				heroImageDesktop={heroData.heroImageDesktop}
				heroImageMobile={heroData.heroImageMobile}
				btnText={heroData.btnText}
				btnLink={heroData.btnLink}
			/>
			<Campaigns campaigns={campaignsData} />
			<Tutorials tutorials={tutorialsData} />
			<FeaturedLinks
				communityFeatured={featuredLinksData.communityFeatured}
				productsFeatured={featuredLinksData.productsFeatured}
			/>
			<MainFooter />
		</Layout>
	);
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				nav {
					path
					icon
					title
					desc
				}
			}
		}
	}
`;
export default CommunityPage;
