const tutorialsData = [
	{
		title: 'SERENA',
		description:
			'Violin lessons and studio recordings',
		link: 'https://www.hostjane.com/marketplace/Serena',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./tutor.jpg'),
	},
	{
		title: 'NOE132',
		description:
			'Spanish lessons from a native speaker',
		link: 'https://www.hostjane.com/marketplace/noe132',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./noe132.png'),
	},
	{
		title: 'AMAAN NADEEM',
		description:
			'Software development',
		link: 'https://www.hostjane.com/marketplace/Amaannadeem',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./amaannadeem.jpeg'),
	},
	{
		title: 'CODETILE',
		description:
			'WordPress development',
		link: 'https://www.hostjane.com/marketplace/Codetile1994',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./codetile.jpg'),
	},
	{
		title: 'JESS',
		description:
			'Data entry and personal assistant',
		link: 'https://www.hostjane.com/marketplace/jess',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./jess_1550256623.png'),
	},
	{
		title: 'OUTSOURCE INDIA',
		description:
			'Web / mobile developers from Bangalore',
		link: 'https://www.hostjane.com/marketplace/outsourceindia',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./outsourceindia.jpg'),
	},
	{
		title: 'ALBIEROBLES',
		description:
			'Professional voice actor for studio voiceovers',
		link: 'https://www.hostjane.com/marketplace/AlbieRobles',
		backgroundImage: require('./background-tutorials.jpg'),
		avatar: require('./albierobles.png'),
	},
];

export default tutorialsData;
