import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
	&.hostjane-main-TutorialCard {
		max-width: 400px;
		width: 85%;
		// margin: 0 auto;
		margin: 15px 15px;

		&:hover {
			transition: box-shadow 0.28s ease-in-out;
			box-shadow: 0 1px 3px 0 rgba(1, 67, 163, 0.8),
				0 4px 8px 3px rgba(1, 67, 163, 0.8);
			border: none;
			.hostjane-main-TutorialCard__card-wrapper {
				.hostjane-main-TutorialCard__card-wrapper__card {
					.hostjane-main-TutorialCard__card-wrapper__card__card-top {
					}
					.hostjane-main-TutorialCard__card-wrapper__card__card-content {
						h3 {
							color: #2b85e7;
						}
						p {
							color: #2b85e7;
						}
					}
				}
			}
		}
		.hostjane-main-TutorialCard__card-wrapper {
			position: relative;
			display: block;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24),
				0 0 2px rgba(0, 0, 0, 0.12);
			border-radius: 2px;
			background: #fff;
			transition: all 0.3s;
			padding: 0;
			text-decoration: none;
			letter-spacing: 0.01em;
			border: none;
			.hostjane-main-TutorialCard__card-wrapper__card {
				color: #1a73e8;
				outline: 0;
				text-decoration: none;
				text-transform: uppercase;
				.hostjane-main-TutorialCard__card-wrapper__card__card-top {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					padding: 16px 16px 0px;
					> div {
						h3 {
							line-height: 24px;
							padding: 0;
							text-align: left;
							text-transform: none;
							transition: all 0.4s;
							color: #000000;
							font-size: 20px;
							margin: 0;
							padding-bottom: 8px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-weight: 800;
						}
						p {
							color: #000;
							text-transform: none;
							height: 60px;
							font-size: 14px;
							max-width: 100%;
							margin: 0 auto 10px;
							line-height: 1.5;
							padding: 0;
							-webkit-line-clamp: 4;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: normal;
						}
					}
					.hostjane-main-TutorialCard__card-wrapper__card__card-top__avatar {
						width: 100px;
						height: 100px;
						position: relative;
						&:before {
							position: absolute;
							top: 5px;
							left: 5px;
							right: 5px;
							bottom: 5px;
							content: '';
							border-radius: 50%;
							border: 5px solid #007bff;
							background-color: white;
							background-image: url(${props => props.avatar});
							background-size: 100px 100px;
							background-repeat: no-repeat;
							background-position: center center;
						}
					}
					padding-bottom: 16px;
				}
				.hostjane-main-TutorialCard__card-wrapper__card__card-content {
					border-top: 1px solid #eceff1;
					padding: 10px 16px 12px;
				}
			}
		}
	}
`;

const TutorialCard = ({
	title,
	description,
	backgroundImage,
	avatar,
	link,
}) => {
	return (
		<Card
			className="hostjane-main-TutorialCard"
			backgroundImage={backgroundImage}
			avatar={avatar}
		>
			<div className="hostjane-main-TutorialCard__card-wrapper">
				<a
					className="hostjane-main-TutorialCard__card-wrapper__card"
					href={link}
					alt=""
				>
					<div className="hostjane-main-TutorialCard__card-wrapper__card__card-top">
						<div>
							<h3 className="hostjane-main-TutorialCard__card-wrapper__card__card-top__title">
								{title}
							</h3>
							<p>{description}</p>
						</div>
						<div className="hostjane-main-TutorialCard__card-wrapper__card__card-top__avatar" />
					</div>
					<div className="hostjane-main-TutorialCard__card-wrapper__card__card-content">
						<div className="hostjane-main-TutorialCard__card-wrapper__card__bottom-border" />
						Visit Studio
					</div>
				</a>
			</div>
		</Card>
	);
};

export default TutorialCard;
